import ApiService from "@/common/api.service";
import { apiResource } from "@/common/app.config";
import { createRouter, createWebHistory } from "vue-router";
import NotFound from "../views/NotFound.vue";
import store from "@/store";
import TokenService from "@/common/token.service";
import BaseRoutes from "./BaseRoutes";
import MirorRoutes from "./MirorRoutes";
import { publicPages } from "@/common/publicPages.js";

function getRoutes() {
  const businessSlug = TokenService.getToken("business_slug");
  const useMirorRoutes =
    businessSlug === process.env.VUE_APP_BUSINESS_SLUG_MIROR;
  return [
    ...(useMirorRoutes ? MirorRoutes : BaseRoutes),
    {
      path: "/too-many-requests",
      name: "TooManyRequests",
      component: () =>
        import(
          /* webpackChunkName: "toomanyrequests" */ "../views/TooManyRequests.vue"
        ),
    },
    {
      path: "/maintenance",
      name: "Maintenance",
      component: () =>
        import(
          /* webpackChunkName: "maintenance" */ "../views/Maintenance.vue"
        ),
    },
    {
      path: "/:catchAll(.*)*",
      name: "NotFound",
      component: NotFound,
    },
  ];
}

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: getRoutes(),
});

async function validateBusinessSlug(to, next) {
  try {
    const { data } = await ApiService.get(apiResource.checkBusinessSlug, {
      slug: to.params.business,
    });

    if (data.status) {
      // Save validated business data
      TokenService.saveToken("business_slug", data.data.slug);
      TokenService.saveToken("business_name", data.data.name);
      TokenService.saveToken("business_location", data.data.location);
      TokenService.saveToken("business_category", data.data.category);
      TokenService.saveToken("business_logo", data.data.logo);
      TokenService.saveToken("business_src", data.data.src);
      TokenService.saveToken("login_type", data.data.login_type);

      store.commit("setBusinessSlug", data.data.slug);
      store.commit("setBusinessName", data.data.name);
      store.commit("setBusinessLocation", data.data.location);
      store.commit("setBusinessCategory", data.data.category);
      store.commit("setBusinessLogo", data.data.logo);
      store.commit("setBusinessSrc", data.data.src);
      store.commit("setLoginType", data.data.login_type);

      return true; // Business is valid
    } else {
      // Redirect to NotFound if business is invalid
      next({
        name: "NotFound",
        params: { catchAll: to.params.business },
      });
      return false;
    }
  } catch (error) {
    // Handle API errors and redirect to NotFound
    next({
      name: "NotFound",
      params: { catchAll: to.params.business },
    });
    return false;
  }
}

router.beforeEach(async (to, from, next) => {
  if (to.params.business) {
    // Validate business slug for all routes with a business parameter
    const storedSlug = TokenService.getToken("business_slug");

    if (!storedSlug || to.params.business !== storedSlug) {
      const isValid = await validateBusinessSlug(to, next);
      if (!isValid) return; // If validation failed, stop navigation
    }
  }

  if (publicPages.includes(to.name)) {
    // Allow public pages without authentication
    return next();
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Require authentication for protected routes
    if (!store.getters.isAuthenticated) {
      return next({
        name: "Register",
        params: { business: to.params.business },
        query: { redirect: to.fullPath }, // Save the target page for post-login redirect
      });
    }
  }

  // Proceed to the requested page
  return next();
});

// router.beforeEach(async (to, from, next) => {
//   if (publicPages.includes(to.name)) {
//     next();
//   } else {
//     if (to.matched.some((record) => record.meta.requiresAuth)) {
//       if (to.params.business != TokenService.getToken("business_slug")) {
//         store.commit("purgeAuth");
//         router.matcher = createRouter({
//           history: createWebHistory(),
//           routes: getRoutes(),
//         }).matcher;
//         next({
//           name: "Register",
//           params: { business: to.params.business },
//           query: { redirect: to.fullPath },
//         });
//       } else if (!store.getters.isAuthenticated) {
//         next({ name: "Register", params: { business: to.params.business } });
//       } else if (to.name === "Register") {
//         next({ name: "Lobby", params: { business: to.params.business } });
//       } else {
//         next();
//       }
//     } else {
//       if (to.params.business) {
//         try {
//           const { data } = await ApiService.get(apiResource.checkBusinessSlug, {
//             slug: to.params.business,
//           });
//           if (data.status) {
//             if (to.params.business != TokenService.getToken("business_slug")) {
//               store.commit("purgeAuth");
//               router.matcher = createRouter({
//                 history: createWebHistory(),
//                 routes: getRoutes(),
//               }).matcher;
//               location.reload();
//             }
//             TokenService.saveToken("business_slug", data.data.slug);
//             TokenService.saveToken("business_name", data.data.name);
//             TokenService.saveToken("business_location", data.data.location);
//             TokenService.saveToken("business_category", data.data.category);
//             TokenService.saveToken("business_logo", data.data.logo);
//             TokenService.saveToken("business_src", data.data.src);
//             TokenService.saveToken("login_type", data.data.login_type);
//             store.commit("setBusinessSlug", data.data.slug);
//             store.commit("setBusinessName", data.data.name);
//             store.commit("setBusinessLocation", data.data.location);
//             store.commit("setBusinessCategory", data.data.category);
//             store.commit("setBusinessLogo", data.data.logo);
//             store.commit("setBusinessSrc", data.data.src);
//             store.commit("setLoginType", data.data.login_type);
//             if (store.getters.isAuthenticated) {
//               next({ name: "Lobby", params: { business: to.params.business } });
//             } else {
//               next();
//             }
//           } else {
//             next({
//               name: "NotFound",
//               params: { catchAll: to.params.business },
//             });
//           }
//         } catch (error) {
//           next({ name: "NotFound", params: { catchAll: to.params.business } });
//         }
//       } else {
//         next();
//       }
//     }
//   }
// });

export default router;
